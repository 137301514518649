<script>
  import {createEventDispatcher} from 'svelte'
  import {lang} from './lang'
  import {projList} from './myData'

  export let showProject

  const dispach = createEventDispatcher()
  let selectedID
</script>

<style lang="scss">
  h1,
  h2,
  h3 {
    color: #333;
  }
  .proj-list {
    // position: absolute;
    right: 0;
    width: 54%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 50vh;
    padding-top: 5vh;
    &.bw {
      filter: saturate(10%);
    }
  }

  // list item shared styles
  .hts {
    transition: all ease-in-out 0.4s;
    transform: translateX(30px);
    opacity: 0;
    pointer-events: none;
  }
  .item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 3rem;
    cursor: pointer;
    position: relative;
    &:hover {
      .title > img {
        transform: scale(1.3) translateX(-30px);
      }
      .title > h1 {
        transform: scale(0.75) translateX(-20px);
        // color: white;
      }
      .hts {
        z-index: 9999;
        opacity: 1;
        transform: translateX(0);
        pointer-events: visible;
      }
      .title__time {
        // transform: translateX(6px);
        transform: translateX(-18px);
      }
    }
    &.selected {
      filter: saturate(100%);
    }
    .title {
      padding-right: 26px;
      position: relative;
      // display: flex;
      & > *:first-child {
        transition: all 0.2s ease-in-out;
        // filter: drop-shadow(3px 6px 10px #999);
      }
    }
    .title__time {
      position: absolute;
      top: 0;
      right: 0;
      // position: absolute;
      // right: 0;
      transition-duration: 0.45s;
      width: 30px;
      font-size: 1.15rem;
    }
    // .title__year{
    //    font-size:1rem;
    //   }
    .logo {
      // box-shadow: 5px 7px 26px #999;
      box-shadow: 3px 6px 10px #999;
      border-radius: 18px;
      width: 4.5rem;
      object-fit: scale-down;
    }
    .title {
      // &.s{
      //   .logo{
      //   }
      // }&.m{
      //   .logo{
      //   }
      // }
      //       &.l{
      //         .logo{
      //           transform: translateX(-20px) scale(1.1);
      // // width:5rem;
      //         }
      //       }
    }
    .intro {
      position: absolute;
      // top: -10px;
      right: 250px;
      img {
        max-width: 20vw;
        width: 20vw;
      }
      h2 {
        width: max-content;
      }
    }
  }
  img {
    max-width: 300px;
    object-fit: contain;
  }

  .no-click {
    cursor: default;
    &:hover {
      .title > img {
        transform: none;
        // transform: translateX(-30px);
      }
      .title > h1 {
        transform: none;
      }
    }
  }
  // specific styles for each
  .year-tag {
    pointer-events: none;
    margin: 0;
  }
  .year-s {
    h1 {
      font-size: 1.3rem;
      border: solid 3px #68e;
      border-width: 0 0 3px;
      color: #68e;
    }
  }
  .spotify {
    .logo {
      box-shadow: none;
      width: 16rem;
      border-radius: 0;
    }
  }
  .adicted {
    .logo {
      border-radius: 50%;
    }
  }
  .jike {
    // margin-bottom: 1rem;
  }
  .knots {
    // margin-bottom: 1rem;
  }
  .square {
  }
</style>

<div class="proj-list" class:bw={showProject}>
  {#each projList as proj}
    <div
      class={'item ' + proj.id}
      class:no-click={proj.noClick}
      class:selected={selectedID === proj.id}
      on:click={(e) => {
        if (proj.noClick) return
        selectedID = proj.id
        dispach('selectitem', proj)
      }}
      on:mouseover={() => {
        dispach('hoveritem', proj)
      }}
      on:mouseout={() => {
        dispach('leaveitem', proj)
      }}>
      <div class={'title ' + proj.size}>
        {#if proj.type === 'text'}
          <h1>{proj.title}</h1>
        {:else}
          <img
            class="logo"
            src={proj.logoUrl + (proj.useRawImg ? '' : '-small.jpg')}
            alt="" />
        {/if}
        {#if !proj.isYearTag}
          <span
            class="hts title__time">{proj.time.split(',')[0].trim()}.<br /><span class="title__year">{proj.time.split(',')[1]}</span></span>
        {/if}
      </div>
      {#if !proj.noIntro}
        <div class="hts intro">
          <img
            src={proj.coverUrl + (proj.useRawImg ? '' : '-small.jpg')}
            srcset=""
            alt="" />
          <h2>{proj.title}</h2>
          <p>{proj.brief}</p>
        </div>
      {/if}
    </div>
  {/each}
</div>
