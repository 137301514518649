const cn = {
  toString: () => 'cn',
  adicted: {
    name: 'adicted',
    intro: '更快一步的浏览器翻译插件',
  },
}
const en = {
  toString: () => 'en',
  adicted: {
    name: 'adicted',
    intro: 'The plugin that pushes translation one step further.',
  },
}

export let lang = navigator.language.match(/zh-cn|zh-CN/) ? cn : en
export const isCN = lang.toString() === 'cn'

export function setLang(l) {
  lang = l === 'cn' ? cn : en
}
