console.log('process?', __process)

function createWrapper(ghRepoPath, prePath) {
  return function jdurl(localPath) {
    return 'https://cdn.jsdelivr.net/gh/' + ghRepoPath + prePath + localPath
  }
}
function jdUrl(url) {
  const wraper = createWrapper('Envl/_/', 'pf/')
  if (__process.env.NODE_ENV !== 'production') {
    return url
  } else {
    return wraper(url)
  }
}

export const projList = [
  {
    id: 'spotify',
    size: 'spotify-l',
    logoUrl:
      'https://spotify-github-profile.vercel.app/api/view?uid=yaoooo_&cover_image=false',
    noIntro: true,
    noClick: true,
    tags: ['music'],
    time: ',',
  },
  // {
  //   id: 'site-2020',
  //   title: 'This Website',
  //   type: 'text',
  //   noIntro: true,
  //   noClick: true,
  //   tags: ['svelte', 'html5', 'css', 'ui'],
  //   time: 'Sept,2020',
  // },
  {
    id: 'adicted',
    size: 's',
    title: 'adicted',
    useRawImg: true,
    logoUrl: jdUrl('img/list/adicted.png'),
    coverUrl: jdUrl('img/list/adicted-preview.png'),
    url:
      'https://microsoftedge.microsoft.com/addons/detail/adicted/dghgahgdekffflappaiofmlilppifgme',
    brief: '换个思路记单词',
    tags: ['javascript', 'css', 'html5', 'ux', 'user research'],
    time: 'Sept, 2020',
    role: ['Development', 'Design'],
  },
  {
    id: 'jike',
    size: 'm',
    title: '即刻Lite',
    logoUrl: jdUrl('img/list/jike.png'),
    coverUrl: jdUrl('img/list/jike.png'),
    url: 'https://jike.gnimoay.com/',
    brief: '即刻App的非官方客户端',
    tags: ['svelte', 'css', 'html5', 'javascript', 'ui'],
    time: 'Aug, 2020',
    role: ['Development', 'Design'],
  },
  {
    id: 'knots',
    size: 'l',
    title: 'Knots App',
    logoUrl: jdUrl('img/list/knots.jpg'),
    coverUrl: jdUrl('img/list/knots.jpg'),
    url:
      'https://www.figma.com/proto/5oBa6FyM4pzB3YfJbgVew3/Knots?node-id=0%3A3&frame-preset-name=iPhone%2011%20Pro%20%2F%20X&scaling=scale-down',
    brief: '为播客与音乐之发现而设计的客户端',
    tags: ['ui', 'ux', 'user research', 'usability testing', 'figma'],
    time: 'July, 2020',
    role: ['Development', 'Design', 'PM'],
  },
  {
    id: 'tt',
    size: 's',
    title: '烫头',
    logoUrl: jdUrl('img/list/yuqian.png'),
    coverUrl: jdUrl('img/list/yuqian.png'),
    url: 'https://toys.gnimoay.com/#/tangtou',
    brief: '照片烫头器',
    tags: ['svelte', 'javascript', 'css', 'html5', , 'ui'],
    time: 'July, 2020',
    role: ['Development', 'Design'],
  },
  {
    id: 'toys',
    size: 'm',
    title: '啊!玩具!',
    useRawImg: true,
    logoUrl: jdUrl('img/list/crown-192.png'),
    coverUrl: jdUrl('img/list/toys.png'),
    url: 'https://toys.gnimoay.com/',
    brief:
      '移动端小项目合辑： 相机|卡片日记|网络名片|竖排文字|二维码生成|油画照片|等等',
    tags: ['svelte', 'javascript', 'css', 'html5', 'ui'],
    time: 'May, 2020',
    role: ['Development', 'Design'],
  },

  {
    id: 'square',
    size: 's',
    title: '方块日记',
    useRawImg: true,
    logoUrl: jdUrl('img/list/square.png'),
    coverUrl: jdUrl('img/list/square.jpg'),
    url: 'https://toys.gnimoay.com/#/square',
    brief: '卡片日记生成器',
    tags: ['svelte', 'javascript', 'css', 'html5', 'ui'],
    time: 'Apr, 2020',
    role: ['Development', 'Design'],
  },
  {
    id: 'stkm',
    size: 'l',
    title: 'STKM',
    logoUrl: jdUrl('img/list/stkm-logo.jpg'),
    coverUrl: jdUrl('img/list/stkm.jpg'),
    url: 'https://stkm.gnimoay.com/',
    brief:
      'An online camera which allows you to take stacked photos with captions.',
    tags: ['svelte', 'javascript', 'css', 'html5', 'ux', 'ui'],
    time: 'Mar, 2020',
    role: ['Development', 'Design'],
  },
  {
    id: 'zmd',
    size: 'm',
    title: '芝麻地',
    type: 'text',
    coverUrl: jdUrl('img/list/stkm.jpg'),
    url: 'https://zmd.hedwig.pub/',
    brief: '一份关于编程与设计的邮报',
    tags: [],
    time: 'Mar, 2020',
    role: ['Development', 'Design'],
  },
  {
    id: 'btc',
    size: 'm',
    title: 'Bitcoin Visualization',
    type: 'text',
    useRawImg: true,
    coverUrl: jdUrl('img/list/btc.svg'),
    url:
      'https://cors-anywhere.herokuapp.com/https://observablehq.com/@gnimoay/bitcoin-viz',
    brief:
      'Visualization on news about BTC to find their influence on the price.',
    tags: ['d3.js', 'javascript', 'Visualization'],
    time: 'Feb, 2020',
    role: ['Development', 'Design'],
  },
  {
    id: 'calc',
    size: 's',
    title: 'Sketch Calculator',
    type: 'text',
    useRawImg: true,

    coverUrl: jdUrl('img/list/calc.png'),
    url: 'https://observablehq.com/@gnimoay/sketch-calculator',
    brief: 'Learn basics of ML by create ur own sketch calculator.',
    tags: ['javascript', 'Machine Learning'],
    time: 'Feb, 2020',
    role: ['Development', 'Design'],
  },
  {
    id: 'bottle',
    size: 's',
    title: '小瓶子',
    logoUrl: jdUrl('img/list/bottle.png'),
    coverUrl: jdUrl('img/list/bottle.jpg'),
    url: 'https://observablehq.com/@gnimoay/untitled/3',
    brief: '在线涂瓶子',
    tags: ['javascript', 'css', 'html5', 'Visualization'],
    time: 'Feb, 2020',
    role: ['Development', 'Design'],
  },
  {
    id: 'year-tag',
    size: 'year-s',
    title: '2019',
    type: 'text',
    // time:'Mar-Aug,2020',
    isYearTag: true,
    noIntro: true,
    noClick: true,
  },
  {
    id: 'bagroutte',
    size: 's',
    title: 'Bagroutte',
    type: 'text',
    coverUrl: jdUrl('img/list/bagroutte.jpg'),
    brief:
      'A design concept derives from StyleBox into a map App, which facilitate the edit, manipulationg and reuse of route.',
    tags: ['usability testing', 'Interaction Design', 'user research'],
    time: 'Nov, 2019',
  },
  {
    id: 'rugbeats',
    size: 's',
    title: 'Rugbeats',
    type: 'text',
    coverUrl: jdUrl('img/list/rugbeats.png'),
    url: 'https://2019.gnimoay.com/post/rugbeats',
    brief:
      'A rhythm action rugby game where two players have to get the "ball" into another player\'s home.',
    tags: ['Game dev', 'Java'],
    time: 'Nov, 2019',
  },
  {
    id: 'site-2019',
    title: 'My Website(2019)',
    coverUrl: jdUrl('img/list/site2019.png'),
    url: 'https://2019.gnimoay.com',
    type: 'text',
    brief: 'My first personal website built with React',
    tags: ['javascript', 'css', 'html5', 'react', 'ui'],
    time: 'Nov,2019',
  },
  {
    id: 'oapack',
    size: 'm',
    title: 'OaPack',
    type: 'text',
    useRawImg: true,
    coverUrl: jdUrl('img/list/oapack.png'),
    url: 'https://2019.gnimoay.com/post/oapack',
    brief:
      'A playful React UI library I design & developed for this website :)',
    tags: ['javascript', 'css', 'react', 'html5'],
    time: 'Nov, 2019',
  },

  {
    id: 'stylebox',
    size: 's',
    title: 'StyleBox',
    type: 'text',
    coverUrl: jdUrl('img/list/stylebox.jpg'),
    url: 'https://2019.gnimoay.com/post/stylebox',
    brief:
      'It borrows the concept of class and the idea of CSS from coding to create a tool that makes styling shapes in design software easier.',
    tags: ['Interaction Design'],
    time: 'Oct, 2019',
  },

  {
    id: 'spotioid',
    size: 's',
    title: 'Spotioid',
    type: 'text',
    coverUrl: jdUrl('img/list/spoti.jpg'),
    brief:
      'Info Visualization of data from Spotify, including genre, user distribution, trending, etc.',
    tags: ['Visualization', 'JavaScript', 'CSS', 'HTML5', 'React'],
    url: 'https://spotioid.now.sh/',
    time: 'Oct, 2019',
  },
  {
    id: 'yearmeter',
    size: 's',
    title: 'Year Meter',
    type: 'text',
    coverUrl: jdUrl('img/list/yearmeter.png'),
    brief:
      'A screen saver that counts the progress of this year and that of today.',
    tags: ['Win32 api', 'c'],
    isGH: true,
    url:
      'https://raw.githubusercontent.com/Envl/WhatColorIsIt-ScreenSaver-Win32/year-meter/README.md',
    rawUrl: 'https://github.com/Envl/WhatColorIsIt-ScreenSaver-Win32',
    time: 'Sept, 2019',
  },
  {
    id: 'bookio',
    size: 'm',
    title: 'BOOKIO',
    type: 'text',
    coverUrl: jdUrl('img/list/bookio.jpg'),
    url: 'https://bookio-5c798.firebaseapp.com/',
    brief: 'A free book sharing platform created using React.js.',
    tags: ['react', 'javascript', 'css', 'html5', 'ui', 'ux'],
    role: ['Development', 'UX', 'PM'],
    time: 'Mar, 2019',
  },

  {
    id: 'spomo',
    size: 's',
    title: 'sPomo',
    useRawImg: true,
    logoUrl: jdUrl('img/list/spomo.png'),
    coverUrl: jdUrl('img/list/spomo.png'),
    brief: 'Simple Pomodoro software on windows, built with AutoHotKey.',
    tags: ['AHK'],
    isGH: true,
    url: 'https://raw.githubusercontent.com/Envl/sPomo/master/README.md',
    rawUrl: 'https://github.com/Envl/sPomo',
    time: 'Mar, 2019',
  },
  {
    id: 'year-tag',
    size: 'year-s',
    title: '2018-2015',
    type: 'text',
    // time:'Mar-Aug,2020',
    isYearTag: true,
    noIntro: true,
    noClick: true,
  },
  {
    id: 'shopwalker',
    size: 's',
    title: 'ShopWalker',
    type: 'text',
    coverUrl: jdUrl('img/list/shopwalker.jpg'),
    tags: [
      'usability testing',
      'user research',
      'ui',
      'UX',
      'Cognitive walkthrough',
      'heuristic evaluation',
    ],
    brief:
      'The design and evaluation of an application which targets to facilitate daily work of a very special group – shopwalkers.',
    time: 'Oct, 2018',
  },

  {
    id: 'seim',
    title: 'SEIM',
    size: 'm',
    useRawImg: true,
    logoUrl: jdUrl('img/list/seim.png'),
    coverUrl: jdUrl('img/list/seim.png'),
    url: 'https://www.youtube.com/watch?v=v6LTm7YHiJU',
    brief:
      'An interactive music installation that involves projection mapping, tangible interaction, generative animation, etc.',
    tags: ['Interaction Design', 'openFrameworks', 'c++'],
    time: 'June, 2017',
  },
  {
    id: 'dvc',
    size: 's',
    title: 'Da Vinci Code',
    logoUrl: jdUrl('img/list/davinci.jpg'),
    coverUrl: jdUrl('img/list/davinci.jpg'),
    isGH: true,
    url:
      'https://raw.githubusercontent.com/Envl/Da_Vinci_Code/master/README.md',
    rawUrl: 'https://github.com/Envl/Da_Vinci_Code',
    brief:
      'A online multiplayer version of the board game Da Vinci Code, built with Unity3D.',
    tags: ['Game dev', 'Unity3D', 'c#'],
    time: 'June,2016',
  },
  {
    id: 'boccaro',
    size: 's',
    title: 'Boccaro',
    logoUrl: jdUrl('img/list/boccaro.jpg'),
    coverUrl: jdUrl('img/list/boccaro.jpg'),
    isGH: true,
    url: 'https://raw.githubusercontent.com/Envl/Boccaro/master/README.md',
    rawUrl: 'https://github.com/Envl/Boccaro',
    brief:
      'An app to introduce traditional Chinese culture about Bocarro, built with Unity3D.',
    tags: ['Unity3D', 'c#'],
    time: 'May,2016',
  },
  {
    id: 'circularclear',
    size: 's',
    title: 'Circular Clear',
    useRawImg: true,
    logoUrl: jdUrl('img/list/circularclear.jpg'),
    coverUrl: jdUrl('img/list/circularclear.jpg'),
    url: 'https://2019.gnimoay.com/post/circular-clear',
    brief:
      'A match-three puzzle game inspired by Spirograph, built with Unity3D.',
    tags: ['Game dev'],
    time: 'Apr,2016',
  },
  {
    id: 'tetris',
    size: 's',
    title: 'Tetris.Dual',
    type: 'text',
    coverUrl: jdUrl('img/list/tetris.jpg'),
    isGH: true,
    url: 'https://raw.githubusercontent.com/Envl/Tetris.dual/master/README.md',
    rawUrl: 'https://github.com/Envl/Tetris.dual',
    brief: 'Dual player Tetris game on Android that uses Bluetooth to connect.',
    tags: ['Game dev', 'Android', 'Java'],
    time: 'Mar,2016',
  },
  {
    id: 'bulletgo',
    size: 's',
    title: 'BulletGO',
    type: 'text',
    useRawImg: true,
    coverUrl: jdUrl('img/list/bullet.png'),
    brief:
      'A comprehensive bullet comment solution through Wechat, PyQt and Node.js.',
    tags: ['Python', 'Node.js', 'PyQt', 'JavaScript'],
    isGH: true,
    url: 'https://raw.githubusercontent.com/Envl/BulletGo/master/README.md',
    rawUrl: 'https://github.com/Envl/BulletGo',
    time: 'June,2015',
  },
  {
    id: 'tangiblex',
    size: 'm',
    title: 'Tangible X',
    type: 'text',
    coverUrl: jdUrl('img/list/tangibleX.jpg'),
    brief:
      'A exploration on tangible interaction for browser and gaming using Kinect.',
    tags: ['Interaction Design', 'Kinect', 'openFrameworks', 'c++'],
    url: 'https://youtu.be/YkSXkH7YLoE',
    time: 'Mar,2015',
  },
]
export const projMap = projList.reduce((acc, cur) => {
  acc[cur.id] = cur
  return acc
}, {})

export const tags = [
  {
    text: '🎵',
    toString: () => 'music',
    toLowerCase: () => 'music',
  },
  'Interaction Design',

  {
    text: 'Product Design',
    toString: () => 'UX',
    toLowerCase: () => 'ux',
  },
  {
    text: 'Basic UI Design',
    toString: () => 'UI',
    toLowerCase: () => 'ui',
  },
  'User Research',
  'Heuristic Evaluation',
  'Cognitive Walkthrough',
  '🚴‍♂️',
  'Color Science',
  'Usability Testing',
  'Design System',
  'Business Model Canvas',
  'SketchUp',
  'Adobe XD',
  'Figma',
  'Creative Coding',
  '🏓',
  'Game Dev',
  'p5.js',
  'VR',
  'AR',
  '📷',
  'Visualization',
  'd3.js',
  'JavaScript',
  '🍟',
  'React',
  'Svelte',
  'CSS',
  'HTML5',
  'OpenCV',
  'C',
  'C#',
  'Unity3D',
  '🎮',
  {
    text: 'Auto Hot Key',
    toString: () => 'AHK',
    toLowerCase: () => 'ahk',
  },
  'Kinect',
  '🏸',
  'Machine Learning',
  'C++',
  'openFrameworks',
  'Processing',
  'Python',
  'PyQt',
  'Win32 API',
  'Node.js',
  'Java',
  'Android',
]

export const proj2tag = projList.reduce((acc, cur) => {
  acc[cur.id] = cur.tags
  return acc
}, {})
console.log('postList', proj2tag)

export const tag2proj = {}
Object.keys(proj2tag).forEach((proj) => {
  proj = proj.toLowerCase()
  if (proj2tag[proj] == undefined) return
  proj2tag[proj].forEach((tag) => {
    // console.log('each tag', tag, proj2tag[proj])
    tag = tag.toLowerCase()
    // console.log('tag', tag, tag2proj, tag in tag2proj)
    if (!(tag in tag2proj)) {
      tag2proj[tag] = []
      // console.log('add tag', tag2proj[tag])
    }
    if (!tag2proj[tag].includes(proj)) tag2proj[tag].push(proj)
  })
})
console.log(tag2proj, 'tag2proj')
