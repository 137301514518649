<script>
  import {spring} from 'svelte/motion'
  import {writable} from 'svelte/store'

  let fill = writable('#69e')
  let coords = spring(
    {x: 50, y: 50},
    {
      stiffness: 0.13,
      damping: 0.87,
    },
  )

  let size = spring(30)
  let lastSize = 30
</script>

<style>
  svg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: -1;
  }
  /* circle {
    fill: #ff3e00;
  } */
</style>

<svelte:window
  on:mousemove={(e) => {
    coords.set({x: e.clientX, y: e.clientY})
    if (e.clientX < (window.innerWidth * 2) / 3 && $size === 30) {
      size.set(15)
      lastSize = 15
      fill.set('#69e')
    } else if (e.clientX > (window.innerWidth * 2) / 3 && $size === 15) {
      size.set(30)
      lastSize = 30
      fill.set('#58ca58')
    }
  }}
  on:mousedown={() => size.set(5)}
  on:mouseup={() => size.set(lastSize)} />
<svg>
  <circle cx={$coords.x} cy={$coords.y} r={$size} fill={$fill} />
</svg>
