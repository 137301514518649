<script>
  import {leaderlineEls, leaderlineEnds} from './model'
  import {onMount} from 'svelte'
  import AnimEvent from 'anim-event'
  import {lang} from './lang'
  import {LeaderLine} from './LeaderLine'
  import {tags, proj2tag, tag2proj, projMap} from './myData'

  let elWordCloud,
    showLineTimer,
    elProjList,
    viewingTag,
    viewingProjLocalIndex = 0,
    // showSingleLineTimers = Array(20).fill(0),
    singleLineTimer,
    footCount,
    visitCount,
    footed = false

  function updateLines(e) {
    // if (isSafari) {
    //   leaderlineEls.forEach((l) => l.hide())
    // } else
    if (viewingTag) {
      const len = leaderlineEnds[viewingTag].length
      for (let i = 0; i < len; i++) {
        //        console.log('position', leaderlineEls[i])
        leaderlineEls[i].position()
      }
    }
  }
  function fire(ends, i) {
    // console.log('fire', i)
    return (
      i < ends.length &&
      setTimeout(
        () => {
          // start and end are exclusively binded to single NodeElement. We need a third node to help with the swapping. Otherwise, the null check of start/end will throw an Error.
          leaderlineEls[i].end = document.body
          leaderlineEls[i].start = ends[i].from
          leaderlineEls[i].end = ends[i].to

          leaderlineEls[i].position()
          leaderlineEls[i].color = '#69e'
          leaderlineEls[i].startSocket = 'right'
          leaderlineEls[i].endSocket = 'auto'
          const title = projMap[ends[i].toID].title
          leaderlineEls[i].middleLabel = title
            ? LeaderLine.captionLabel({
                text: title,
                color: 'black',
                fontWeight: 'bold',
                fontSize: '1.2rem',
              })
            : ''
          leaderlineEls[i].show('fade')
          singleLineTimer = fire(ends, i + 1)
        },
        150,
        0,
      )
    )
  }
  onMount(() => {
    fetch('https://api.countapi.xyz/get/gnimoay.com/footCount')
      .then((rsp) => rsp.json())
      .then((d) => (footCount = d.value))
    fetch(
      `https://api.countapi.xyz/${
        window.location.protocol === 'https:' ? 'hit' : 'get'
      }/gnimoay.com/visit-count-` +
        window.location.pathname.split('/').slice(1).join('-'),
    )
      .then((rsp) => rsp.json())
      .then((d) => (visitCount = d.value))

    elProjList = document.querySelector('.proj-list')
    let counter = 0
    setTimeout(() => {
      Array(14) // currently 14 concurrency at most
        .fill(1)
        .map((a) =>
          leaderlineEls.push(
            new LeaderLine(elWordCloud, elProjList, {
              hide: true,
              color: '#69e',
              startSocket: 'right',
            }),
          ),
        )

      Object.keys(tag2proj).forEach((tag) =>
        tag2proj[tag].forEach((proj) => {
          // console.log(
          //   'start',
          //   tag,
          //   'tagel',
          //   elWordCloud.querySelector(`.word-cloud__tag[data-tag="${tag}"]`),
          //   'end',
          //   proj,
          //   'proj el',
          //   elProjList.querySelector('.' + proj + ' .intro h2'),
          // )
          const elTag = elWordCloud.querySelector(
              `.word-cloud__tag[data-tag="${tag}"]`,
            ),
            elProj = elProjList.querySelector('.' + proj)
          const introH = elProj.querySelector('.intro h2')
          if (!(elProj && elTag)) {
            return
          }
          if (!(tag in leaderlineEnds)) leaderlineEnds[tag] = []
          if (!(proj in leaderlineEnds)) leaderlineEnds[proj] = []
          leaderlineEnds[tag].push({
            from: elTag,
            to: elProj,
            fromID: tag,
            toID: proj,
          })
          leaderlineEnds[proj].push({
            from: introH || elProj,
            to: elTag,
            fromID: proj,
            toID: tag,
          })
        }),
      )
      return () => {
        leaderlineEls.forEach((l) => l.remove())
        leaderlineEls.length = 0
        AnimEvent.remove(updateLines)
      }
    }, 1300)
  })
</script>

<style lang="scss">
  .word-cloud {
    display: flex;
    flex-wrap: wrap;
  }
  .word-cloud__tag {
    border-radius: 62px;
    margin: 3px 12px 3px 0;
    padding: 3px 10px;
    border: 3px solid #333;
    transition: border-color 0.3s ease;
    &:hover {
      border-color: var(--light-blue);
      // &::after {
      // border: solid 1px var(--light-blue);
      // background-color: var(--light-blue);
      // }
    }
    &.refed {
      cursor: pointer;
      pointer-events: visible;
      // border-style: dashed;
      // border-color: var(--light-blue);
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        transition: background-color 0.3s ease;
        border-radius: 10px;
        top: calc(50% - 5px);
        right: -6px;

        background-color: #68ea;
        // background-color: rgba(238, 102, 102, 200);
      }
    }
  }

  .foot-btn {
    cursor: pointer;
    background: wheat;
    &:disabled {
      color: #333;
      cursor: not-allowed;
    }
    &.footed {
      background: #68e5;
      border-color: var(--light-blue);
    }
  }
</style>

<svelte:window on:scroll={AnimEvent.add(updateLines)} />
<div
  bind:this={elWordCloud}
  class="word-cloud"
  on:mouseover={(e) => {
    viewingProjLocalIndex = 0

    const tag = e.target.getAttribute('data-tag')
    if (!Object.keys(tag2proj).includes(tag)) return
    if (!(tag in leaderlineEnds)) return
    viewingTag = tag

    //leaderlineEnds[tag].forEach((ends, i) => {
    //showSingleLineTimers[i] = setTimeout(() => {

    singleLineTimer = fire(leaderlineEnds[tag], 0)
    //})
  }}
  on:mouseout={(e) => {
    clearTimeout(singleLineTimer)
    //showSingleLineTimers.forEach((t) => clearTimeout(t))
    viewingTag = null
    const tag = e.target.getAttribute('data-tag')
    if (!tag || !(tag in leaderlineEnds)) return
    leaderlineEls.forEach((l) => l.hide('fade'))
  }}
  on:click={(e) => {
    const tag = e.target.getAttribute('data-tag')
    if (!tag || !(tag in leaderlineEnds)) return
    const pairs = leaderlineEnds[tag]
    pairs[viewingProjLocalIndex].to.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    viewingProjLocalIndex = viewingProjLocalIndex >= pairs.length - 1 ? 0 : viewingProjLocalIndex + 1
  }}>
  {#each tags as tag}
    <div
      class="word-cloud__tag"
      class:refed={Object.keys(tag2proj).includes(tag.toLowerCase())}
      data-tag={tag.toLowerCase()}>
      {tag.text || tag}
    </div>
  {/each}
  <div class="word-cloud__tag">my website had {visitCount} visits</div>
  <button
    class="word-cloud__tag foot-btn"
    class:footed
    disabled={footed}
    on:click={() => {
      fetch('https://api.countapi.xyz/hit/gnimoay.com/footCount')
      footCount++
      footed = true
    }}>
    {footCount} 人来踩过
  </button>
</div>
